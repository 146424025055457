const header = {

  /**
   * Controla abertura do menu
   */
  controlMenu: () => {
    document.querySelectorAll('.act-open-menu-itens').forEach(container => {
      container.addEventListener('click', e => {

        if (document.getElementById(`cloned-div`)) document.getElementById(`cloned-div`).remove()

        if (window.innerWidth < 1024) {
          const cloned = document.querySelector(`[data-menu-itens=${e.target.dataset.menu}]`).cloneNode(true)
          cloned.setAttribute('id', 'cloned-div')
          
          document.getElementById(`column-${e.target.dataset.after}`).appendChild(cloned)
        } else {
          document.querySelectorAll(`[data-menu-itens]`).forEach(item => {
            item.classList.remove('_block')
          })
      
          document.querySelector(`[data-wrapper-menu-itens]`).classList.add('_block')
          document.querySelector(`[data-menu-itens=${e.target.dataset.menu}]`).classList.add('_block')
        }

      })
    })

    document.getElementById('hamburguer-menu').addEventListener('click', e => {
      if (window.innerWidth < 1024) {
        document.body.classList.toggle('_overflow-hidden')
      }
    })
  }

}

export default header
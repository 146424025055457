export default class Autocomplete {

  constructor() {
    this.ROUTE_SEARCH_CITY = "/json/busca-cidades";
    this.ROUTE_SEARCH_BY_NAME = "/json/busca-por-nome";
    this.URL_PATTERN = {
      CITIES: "/previsao-do-tempo/cidade/{ID}/{NAME}-{ACRONYM}",
      BEACHES: "/previsao-do-tempo/litoral/{ID}/{NAME}-{ACRONYM}",
      AIRPORTS: "/previsao-do-tempo/aeroporto/{ID}/{NAME}-{ACRONYM}",
      WORLD: "/previsao-do-tempo/cidade/{ID}/{NAME}-{ACRONYM}"
    };
    this.URL_PATTERN_AGROCLIMA = {
      CITIES: (location.href.includes('www.climatempo.com.br/agroclima') || location.href.includes('agroclima.climatempo.com.br')) ? "https://agroclima.climatempo.com.br/previsao-do-tempo/15-dias/cidade/{ID}/{NAME}-{ACRONYM}" : "/agroclima/previsao-do-tempo/15-dias/cidade/{ID}/{NAME}-{ACRONYM}"
    };
    this.globalResults = [];
    this.requests = [];
    this.inputElement = null;
    this.allTypes = false;
    this.isAgro = false;
    this.find = this.find.bind(this);
    this.create = this.create.bind(this);
    this.appendSearch = this.appendSearch.bind(this);
  }

  /**
   * @method find
   * @description Faz a procura das localidades e verifica/cria cache
   * @param {string} term
   */
  find(term) {
    let results = this.globalResults || [];
    let result;
    let cached = false;
    let indexResults = 0;
    let totalResults = this.globalResults.length || 0;

    // check cache
    for (; indexResults < totalResults; indexResults += 1) {
      result = results[indexResults];
      if (term.toLocaleLowerCase() === result.term.toLowerCase()) {
        cached = true;
        break;
      }
    }

    // execute
    if (cached) {
      this.appendSearch(result.response);
    } else {
      let url = this.ROUTE_SEARCH_CITY;

      if (this.allTypes) url = this.ROUTE_SEARCH_BY_NAME;

      this.requests.push(new Promise(function (resolve, reject){
        $.ajax({
          url,
          type: "POST",
          dataType: "json",
          cache: true,
          context: this,
          data: {name: term},
          success: (response) => resolve({ response, term }),
          error: reject
        });
      }))
    }

    Promise.all(this.requests)
      .then((responses) => {
        let { response, term } = responses.pop()
        
        /*if (this.isAgro) {
          const agriculturalCities = response[0].response.data.filter(item => item.agricultural)
          response[0].response.data = agriculturalCities
        }*/

        this.globalResults.push({ term, response });
        this.appendSearch(response);
        this.requests = [];
    })
  };

  /**
   * @method appendSearch
   * @description Cria os elementos com as localidades e adiciona ao elemento de autocomplete
   * @param {array} results
   */
  appendSearch(results) {
    const autocompleteContainer = $(`#${this.inputElement.attr('id')}_autocomplete`);
    autocompleteContainer.empty();

    for(let i = 0, numResults = results.length; i < numResults; i++) {
      results[i].response = results[i].response || { data: [] };
      let data = results[i].response.data || [];
      let title;

      if (data.length > 0) {
        data = data.slice(0, 4);
        switch (results[i].type) {
          case "city":
            title = "Cidades";
            break;
          case "airport":
            title = "Aeroportos";
            break;
          case "beach":
            title = "Praias";
            break;
        }

        // Adiciona titulo da categorizacao de localidade
        let liTitle = $("<li></li>");
        liTitle.addClass('item -title');
        liTitle.text(title);
        autocompleteContainer.append(liTitle);

        for(let j = 0, numLocales = data.length; j < numLocales; j++) {
          let city, name = "", country = "", id = 0, acronym = "", pattern;
          
          switch (data[j].base || "") {
            case 'cities':
              id = data[j].idcity || 0;
              name = data[j].city || "";
              country = ` (${data[j].country})` || "";
              pattern = this.isAgro ? this.URL_PATTERN_AGROCLIMA.CITIES : this.URL_PATTERN.CITIES;
              break;
            case 'beaches':
              id = data[j].idbeach || 0;
              name = data[j].beach || "";
              pattern = this.URL_PATTERN.BEACHES;
              break;
            case 'airports':
              id = data[j].idairport || 0;
              name = data[j].airport || "";
              pattern = this.URL_PATTERN.AIRPORTS;
              break;
          }

          if ((data[j].uf || "").toUpperCase() === "ND") {
            acronym = data[j].ac || "";
            pattern = this.URL_PATTERN.WORLD;
          } else {
            acronym = data[j].uf || "";
          }

          const liItem = $("<li></li>");
          const aLocale = $("<a></a>");
          const iconLink = $("<i></i>");
          liItem.addClass('item');
          iconLink.addClass('fa fa-arrow-circle-right _padding-l-10');
          aLocale.html(acronym + " - " + name + country);
          aLocale.append(iconLink);
          aLocale.attr('data-lat', data[j].latitude);
          aLocale.attr('data-lon', data[j].longitude);
          aLocale.attr('data-name', name);
          aLocale.attr('data-uf', acronym);
          aLocale.attr('data-locale', data[j].idlocale);
          aLocale.attr('data-city', data[j].idcity);
          aLocale.addClass('link actAddFavoriteLocale');

          if (this.onPress) {
            aLocale.on('click', null, this, function(event) {
              event.data.onPress(event.data.inputElement, $(this))
            });
          } else {
            aLocale.attr('href', this.formatURL(id, name, acronym, pattern));
          }
          
          liItem.append(aLocale);
          autocompleteContainer.append(liItem);
        }
        this.inputElement.siblings('.autocomplete-list').css('height', 'auto');
      }
    }
  }

  formatURL(id, name, acronym, pattern) {
    let slug;

    name = name.replace(new RegExp('[\xc1\xc0\xc2\xc3\xc4\xe1\xe0\xe2\xe3\xe4]', 'gi'), 'a');
    name = name.replace(new RegExp('[\xc9\xc8\xca\xcb\xe9\xe8\xea\xeb]', 'gi'), 'e');
    name = name.replace(new RegExp('[\xcd\xcc\xce\xcf\xed\xec\xee\xef]', 'gi'), 'i');
    name = name.replace(new RegExp('[\xd3\xd2\xd4\xd5\xd6\xf3\xf2\xf4\xf5\xf6]', 'gi'), 'o');
    name = name.replace(new RegExp('[\xda\xd9\xdb\xdc\xfa\xf9\xfc]', 'gi'), 'u');
    name = name.replace(new RegExp('[\xe7]', 'gi'), 'c');
    slug = name.replace(/[\s\'\"\(\)\.\/]/g,'');

    return pattern
      .replace("{ID}", id)
      .replace("{NAME}", slug)
      .replace("{ACRONYM}", acronym)
      .toLowerCase();
  }

  /**
   * @method create
   * @description Cria eventos que irão montar o autocomplete e fazer busca
   * @param {{}} configParam
   */
  create(configParam) {
    let config = configParam || {
      minLength: 3,
      maxResult: 5,
      onPress: function() {},
    };

    // param validate
    if (!config.input.is(':input')) {
      throw "Climatempo.Search Error: Invalid input, is not a jQuery element";
    }
    if (typeof config.minLength !== 'number') {
      config.minLength = 3;
    }
    if (typeof config.maxResult !== 'number') {
      config.maxResult = 4;
    }
    this.inputElement = config.input;
    this.onPress = config.onPress;
    this.allTypes = config.allTypes;
    this.isAgro = configParam.isAgro;

    // Adiciona evento de keyup no input que terá autocomplete
    this.inputElement.off("keyup").on("keyup", null, this, function (event) {
      let textSearch = $(this).val();

      if (textSearch.length >= config.minLength) {
        event.data.find(textSearch);
      } else {
        $(this).siblings('.autocomplete-list').css('height', 0);
      }
    });

    // Detecta saida do input e fecha lista de autocomplete
    this.inputElement.off("blur").on("blur", function (event) {
      $(this).siblings('.autocomplete-list').delay(500).animate({ 'height': 0 }, 10);
    });
  };
}


/**
 * Export component in global context
 */
window.Autocomplete = Autocomplete;
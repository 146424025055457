import common from './common'

const geolocation = {

  /**
   * Requisita ao usuário a sua localização
   *
   * @param callback
   */
  getUserLocation: (callback, error) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(callback, error)
    }
  },

  userGeolocation: () => {
    // verifica se tem cookie
    let cookieCity, cookieLocale, cookieName, cookieUf = null
    let cookies = document.cookie.split(';')

    for (const element of cookies) {
      let temp = element.trim()
      if (temp.indexOf('__geo_city=') == 0) cookieCity = temp.substring(name.length, temp.length)
      if (temp.indexOf('__geo_locale=') == 0) cookieLocale = temp.substring(name.length, temp.length)
      if (temp.indexOf('__geo_name=') == 0) cookieName = temp.substring(name.length, temp.length)
      if (temp.indexOf('__geo_uf=') == 0) cookieUf = temp.substring(name.length, temp.length)
    }

    // if city in the cookie, get from cookie
    // if not, try to get by geolocation
    if (cookieCity && cookieLocale && cookieName && cookieUf) {
      const city = cookieCity.split('=')[1]
      const locale = cookieLocale.split('=')[1]
      const name = cookieName.split('=')[1]
      const uf = cookieUf.split('=')[1]

      geolocation.renderWeatherNowByGeolocation(locale, city)
      common.dynamicLink(city, name, uf)
    } else {
      geolocation.getUserLocation((position) => {
        const url = "/json/minha-localizacao"
        const xhttp = new XMLHttpRequest()

        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            const { idlocale, idcity, city, uf } = JSON.parse(this.response).data[0]

            geolocation.renderWeatherNowByGeolocation(idlocale, idcity, true)
            common.dynamicLink(idcity, city, uf)
          }
        }

        xhttp.open("POST", url, true)
        xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
        xhttp.send(`latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`)
      }, (error) => {
        // set São Paulo as default locale
        geolocation.renderWeatherNowByGeolocation(3477, 558)
        common.dynamicLink(558, 'São Paulo', 'SP')
      })
    }
  },

  /**
   * Create component with forecast now for geolocated city
   *
   */
  renderWeatherNowByGeolocation: (idlocale, idcity, refresh = false) => {
    const url = `/json/myclimatempo/user/weatherNow?idlocale=${idlocale}`
    const xhttp = new XMLHttpRequest()

    try {
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          let response = JSON.parse(this.response)

          let locale = response.data.getWeatherNow[0].data[0].locale
          let weather = response.data.getWeatherNow[0].data[0].weather

          if (window.location.pathname.includes("teste/15-dias") || window.location.pathname.includes("teste-completo/15-dias")) {
            weather.temperature = null
            weather.icon = null
          }

          geolocation.errorHitCurrentWeather(weather)
          let slug = common.createSlug(locale.city)
          let acronym = locale.ac

          if (locale.ac == 'BR') acronym = locale.uf

          const url = `${locale.idcity}/${slug}-${acronym.toLowerCase()}`

          // TODO: verificar se está na home
          if ($('[data-current-weather]').length > 0) {
            if (refresh) {
              //if (cookieCity !== `__geo_city=${idcity}`) {
              const domainName = window.location.hostname

              document.cookie = `__geo_locale=${idlocale}; domain=.${domainName}`
              document.cookie = `__geo_city=${idcity}; domain=.${domainName}`
              document.cookie = `__geo_name=${locale.name}; domain=.${domainName}`
              document.cookie = `__geo_uf=${locale.uf}; domain=.${domainName}`

              location.reload()
              //}
            }
          }

          // geolocated city in array position 0
          const cityName = `${locale.city}, ${acronym}`

          $('[data-id=geolocated]').find('.city').html(cityName)
          $('[data-id=geolocated]').find('.temperature').html(`${weather.temperature}&deg;`)
          // $('[data-id=geolocated]').find('.ico').attr('src', `/dist/images/v2/svg/outline/${weather.icon}.svg`)

          if (window.location.href.includes('agroclima')) {
            $('[data-id=geolocated]').find('.link').attr('href', `/agroclima/previsao-do-tempo/15-dias/cidade/${url}`)
          } else {
            $('[data-id=geolocated]').find('.link').attr('href', `/previsao-do-tempo/cidade/${url}`)
          }

          $('[data-id=geolocated]').removeClass('-hidden-geolocated')

          if ($('[data-current-weather]').length > 0) {
            // const icon = document.createElement('img')

            // icon.src = `/dist/images/v2/svg/wi-thermometer.svg`
            // icon.alt = `Temperatura agora em ${cityName}`
            // icon.width = 60
            // icon.height = 60

            $('#current-weather-city').html(cityName)
            // $('#current-weather-icon').html(icon)
            $('#current-weather-temperature').html(`${weather.temperature}&deg;`)
            $('#current-weather-condition').html(`${weather.condition}`)
            // $('#current-weather-sensation').html(`${weather.sensation}&deg;`)
            $('#current-weather-sensation').html(`${weather.temperature}&deg;`)
            $('#current-weather-humidity').html(`${weather.humidity}%`)
            $('#current-weather-pressure').html(`${weather.pressure} hPa`)
            $('#current-weather-wind').html(`${weather.windVelocity} km/h&nbsp;`)
            $('#current-weather-updated-at').html(`${weather.dateUpdate}`)

            $('[data-current-weather] .shimmer-placeholder').removeClass('shimmer-placeholder')
          }
        }
      }

      xhttp.open("POST", url, true)
      xhttp.send()
    } catch (e) { }
  },

  errorHitCurrentWeather: (weather) => {
    if (!weather.icon) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': 'error_hit', 'name': 'data_aggregator_now_icon' });
    }

    if (!weather.temperature) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'event': 'error_hit', 'name': 'data_aggregator_now_temperature' });
    }
  }

}

export default geolocation

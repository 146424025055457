import common from './common'

const user = {
  
  /**
   * Requisita informações do usuário logado e monta menu no header
   *
   */
  userInfo: () => {
    window.dataLayer = window.dataLayer || [];
    const isLogged = document.cookie.match(new RegExp('(^| )SESSID=([^;]+)'))
    const url = `/json/myclimatempo/user/info?${Math.random(1, 1000)}`

    if (isLogged) {
      const xhttp = new XMLHttpRequest()

      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const response = JSON.parse(this.response)
          
          if (response) {
            window.dataLayer.push({
              'event' : 'is_logged',
              'iumc' : response._id
            })

            let initials = `${response.name.charAt(0).toUpperCase()}`

            $('#isnt-logged-content').remove()

            $('#is-logged-content').removeClass('_none').find('#user-initials').html(initials)
            $('#is-logged-content').find('#user-name').html(`${response.name}`)
            $('#is-logged-content').find('#user-email').html(response.email)

            // verifica se está habilitada a previsão por e-mail
            let enabledEmailForecast = false

            Object.keys(response.emailForecastPreferences).forEach((key, index) => {
              if (response.emailForecastPreferences[key]) {
                enabledEmailForecast = true
              }
            })

            if (!enabledEmailForecast) {
              //$('#popup-register').removeClass('-hidden')
              //$('#popup-register #form-isnt-logged').remove()
              //$('#popup-register #form-logged').removeClass('_none')
            }

            user.renderWeatherNowByFavoritesLocalesBreadcrumb()
          } else {
            $('#is-logged-content').remove()
            $('#isnt-logged-content').removeClass('_none')

            //$('#popup-register').removeClass('-hidden')
          }
        }
      };

      xhttp.open("GET", url, true)
      xhttp.send()
    } else {
      $('#is-logged-content').remove()
      $('#isnt-logged-content').removeClass('_none')

      //$('#popup-register').removeClass('-hidden')
    }
  },

  /**
   * Modal do perfil do usuário localizado no header
   *
   */
  controlLoggedMenu: () => {
    $(document).on('click', '.act-control-logged-menu', function(e) {
      e.preventDefault()

      $('[data-modal=user-logged-menu]').toggleClass('_none')
    })
  },

  /**
   * Lista de localidades favoritas na barra de breadcrumb
   *
   */
  controlFavoritesLocalesBreadcrumb: () => {
    $(document).on('click', '.act-control-favorites-locales-breadcrumb', function(e) {
      e.preventDefault()

      $('[data-id=list-favorites-locales-breadcrumb]').toggleClass('-active')
    })
  },

  /**
   * Render a list with forecast now for all favorites locales
   * Header in all pages
   */
  renderWeatherNowByFavoritesLocalesBreadcrumb: () => {
    const isLogged = document.cookie.match(new RegExp('(^| )SESSID=([^;]+)'))
    const url = `/json/myclimatempo/user/weatherNowByFavoritesLocales?${Math.random(1, 1000)}`

    if (isLogged) {
      const xhttp = new XMLHttpRequest()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {

            try {
              const response = JSON.parse(this.response)

              response.forEach(item => {
                let locale = item.data[0].locale
                let weather = item.data[0].weather[0]
                let slug = common.createSlug(locale.name)
                let acronym = locale.acronym

                if (locale.acronym == 'BR') acronym = locale.uf

                const url = `${locale.city}/${slug}-${acronym.toLowerCase()}`
                const cityName = `${locale.name}, ${acronym}`

                item = `<li class="item">
                          <img class="icon" src="/dist/images/v2/svg/outline/${weather.icon}.svg" width="20" height="20" alt="Ícone de temperatura atual">
                          <a href="/previsao-do-tempo/cidade/${url}" class="-white">
                            ${cityName}
                            /
                            ${weather.temperature}&deg;
                          </a>
                        </li>`

                $('[data-id=list-favorites-locales-breadcrumb').append(item).removeClass('_none')
                $('[data-id=list-favorites-locales-arrow').removeClass('_none')
              })
            } catch (e) { }
          }
        };

        xhttp.open("GET", url, true)
        xhttp.send()
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde")
      }
    }
  },

}

export default user

import 'lazysizes'
import Slugify from 'slugify'
import Autocomplete from './autocomplete'

const common = {

  /**
   * Create slug from string
   *
   */
  createSlug: (string) => {
    const slug = Slugify(string, {
      remove: /[ \']/g,
      url: true,
      lower: true
    })

    return slug
  },
  /**
   * Controla eventos de scroll na página (lazyload)
   *
   */
  onScroll: () => {
    const screenHeight = $(window).height()

    const stickyDesktop = $('[data-banner=stickBannerDesktop')
    const stickyMobile = $('[data-banner=stickBannerMobile')
    const stickyMobileBottom = $('[data-banner=stickBannerMobileBottom')
    const fakeSticky = $('[data-banner=fake-sticky]')

    let beginStickyMobile = 0
    let endStickyMobile = 0
    let beginStickyMobileBottom = 0
    let endStickyMobileBottom = 0

    // verifica se tem sticky na página
    if (stickyMobile.length) {
      beginStickyMobile = stickyMobile.offset().top
      endStickyMobile = $('[data-end-sticky-banner-mobile]').offset().top
    }

    if (stickyMobileBottom.length) {
      beginStickyMobileBottom = $('[data-end-sticky-banner-mobile]').offset().top + 60
      endStickyMobileBottom = $('.footer-site').offset().top - 300
    }

    document.addEventListener('scroll', function (e) {
      let scroll = $(window).scrollTop()

      if (stickyDesktop.length) {
        if (scroll > 200) stickyDesktop.removeClass('-hidden');
      }

      // verifica se tem sticky na página
      if (fakeSticky.length) {
        let fakeStickyPosition = $('[data-control-fake-sticky]').offset().top

        scroll >= (fakeStickyPosition - (screenHeight - 90))
          ? fakeSticky.find('.-close-banner').removeClass('_flex')
          : fakeSticky.find('.-close-banner').addClass('_flex')

        if (scroll > 200) fakeSticky.removeClass('-hidden')
      }

      if (stickyMobile.length) {
        scroll >= beginStickyMobile
          ? stickyMobile.addClass('_fixed')
          : stickyMobile.removeClass('_fixed')

        scroll >= (endStickyMobile + 60)
          ? stickyMobile.addClass('-hidden')
          : stickyMobile.removeClass('-hidden')
      }

      if (stickyMobileBottom.length) {
        scroll >= beginStickyMobileBottom ? stickyMobileBottom.addClass('_fixed') : stickyMobileBottom.removeClass('_fixed')
        scroll >= endStickyMobileBottom ? stickyMobileBottom.addClass('-hidden') : stickyMobileBottom.removeClass('-hidden')
      }

      $('[data-counter]').each(function () {
        let offset = $(this).offset().top - 100
        let activated = $(this).hasClass('-activated')

        if (scroll > offset && !activated) {
          const total = $(this).data('total')

          $(this).addClass('-activated')

          $(this).prop('counter', 0).animate({ counter: total }, {
            duration: 2500,
            easing: 'swing',
            step: function (now) { $(this).text(Math.ceil(now)) }
          })
        }
      })
    }, { passive: true })
  },

  /**
   * Controla abertura da pesquisa mobile
   *
   */
  controlMobileSearch: () => {
    $(document).on('click', '.act-control-mobile-search', function () {
      $(`[data-id='mobile-search']`).slideToggle('fast')
    })
  },

  /**
   * Fecha um banner
   *
   */
  closeBanner: () => {
    $('[data-close-banner]').click(function () {
      $(this).parent().remove()
    })

    $('[data-close-fake-sticky]').click(function () {
      $(this).parent().parent().addClass('_relative')
      $(this).remove()
    })
  },

  /**
   * Adiciona autocomplete de localidades para inputs necessários
   */
  autocompleteSearch: function () {
    const searchGeneral = new Autocomplete()
    const searchGeneralMobile = new Autocomplete()

    const isAgro = $('#searchGeneral').data('is-agro')
    const isAgroMobile = $('#searchGeneralMobile').data('is-agro')

    searchGeneral.create({
      input: $('#searchGeneral'),
      maxResult: 4,
      minLength: 3,
      allTypes: true,
      isAgro
    })

    searchGeneralMobile.create({
      input: $('#searchGeneralMobile'),
      maxResult: 4,
      minLength: 3,
      isAgro: isAgroMobile
    })
  },

  /**
   * Create a dynamic link for menus
   */
  dynamicLink: (idcity, name, uf = '') => {
    const slugBody = $('body').data('slug')
    const slugBreadcrumb = `${idcity}/${common.createSlug(name)}-${uf.toLowerCase()}`

    const slug = slugBody ? slugBody : slugBreadcrumb

    $('[data-dynamic-link]').each(function () {
      let href = $(this).data('link').replace('{slug}', slug)
      $(this).attr('href', href)
    })

    $('[data-dynamic-news-link]').each(function () {
      // const widthScreen = $(window).width()
      const href = $(this).data('link')

      let prefix = '/noticia'
      // if (widthScreen < 1024) prefix += '/amp/v2'

      $(this).attr('href', `${prefix}/${href}`)
    })

    $('[data-dynamic-agroclima-news-link]').each(function () {
      const widthScreen = $(window).width()
      const href = $(this).data('link')
      let prefix = '/agroclima/noticia'

      if (widthScreen < 1024) {
        prefix += '/amp/v2'
      }

      $(this).attr('href', `${prefix}/${href}`)
    })
  },

  /**
   * Controla abertura dos subitens no menu mobile
   *
   */
  controlMobileMenu: () => {
    $(document).on('click', '.actControlMobileMenu', function () {
      const menu = $(this).data('menu')
      const after = $(this).data('after')

      $('#listSubMenusMobile.clone').remove()

      let $area = $('#listSubMenusMobile').clone().addClass('clone')

      $(`[data-sub-menu=${menu}]`).each(function () {
        let item = $(this).clone()

        $area.append(item)
      })

      $('.item-mobile').addClass('-opacity')
      $(this).removeClass('-opacity')

      $(`[data-index-menu=${after}]`).after($area)
    })
  },

  /**
   * Renderiza banner em modais e mapas
   * All Forecasts
   */
  // renderBanner: () => {
  //   $(document).on('click', '.act-render-banner', function() {
  //     if ($(this).hasClass('-rendered')) return

  //     const slots = window.innerWidth < 1024 ? $(this).data('slots-mobile') : $(this).data('slots-desk')

  //     if (!slots) return

  //     slots.forEach((slot) => {
  //       const definedSlot = googletag.defineSlot(`/1030473/${slot.adunit}`, slot.sizes, `${slot.adunit}`).addService(googletag.pubads())

  //       var div = document.createElement('div')
  //       div.id = definedSlot.getSlotElementId()

  //       if (!document.getElementById(`${slot.adunit}_ref`)) return
  //       document.getElementById(`${slot.adunit}_ref`).appendChild(div)

  //       pbjs.que.push(function() {
  //         pbjs.requestBids({
  //           timeout: 3000,
  //           adUnitCodes: [slot.adunit],
  //           bidsBackHandler: function() {
  //             pbjs.setTargetingForGPTAsync([slot.adunit])
  //             googletag.pubads().refresh([definedSlot])
  //             googletag.display(definedSlot)
  //           }
  //         })
  //       })
  //     })

  //     const banner = $(this).data('banner')
  //     $(`[data-banner=${banner}]`).addClass('-rendered')
  //   })
  // },

  /**
   * Contabiliza cliques no GA nos elementos selecionados
   *
   */
  initMetrics: () => {
    $(document).ready(function () {

      $('.actTriggerGA').click(function () {
        const category = $(this).data('category')
        const label = $(this).data('label')
        const action = $(this).data('action')

        // ga('send', {
        //   hitType: 'event',
        //   eventCategory: category,
        //   eventAction: action,
        //   eventLabel: label
        // });
      });

    })
  },

  /**
   * Identifica na aba principal em qual página de previsão o usuário está
   * All Forecasts
   */
  activeTab: () => {
    const tab = $('[data-page=has-tab]').data('tab')
    $('[data-item="' + tab + '"]').addClass('-active')
  },

  /**
   * Identifica na aba principal em qual pagina de previsão o usuário está (MOBILE)
   * All Forecasts
   */
  activeMobileTab: () => {
    const tab = $('[data-page=has-tab]').data('tab')

    if (tab) {
      const left = $('[data-navigation="' + tab + '"]').addClass('-active').offset().left

      const innerWidth = window.innerWidth
      const scrollLeft = (innerWidth / 2) - 60
      const fixedNavigation = document.getElementById('fixedNavigation')

      fixedNavigation.addEventListener('scroll', (ev) => {
        let scroll = ev.target.scrollLeft
        const left = document.getElementById('sideNavigatorLeft')
        const right = document.getElementById('sideNavigatorRight')

        if (scroll < 50) left.classList.add('-hidden')
        else left.classList.remove('-hidden')

        if (scroll > 350) right.classList.add('-hidden')
        else right.classList.remove('-hidden')
      }, { passive: true })

      fixedNavigation.scrollTo({
        left: left - scrollLeft,
        behavior: 'smooth'
      })
    }
  },
  /**
   * Dá compatibilidade em novos tamanhos de imagens do climapress
   */
  fallbackImage: () => {
    $('.fallback').on("error", function () {
      const imageElement = $(this)
      const imageFallback = imageElement.data('fallback')

      if (imageFallback) {
        imageElement.attr('src', imageFallback)
      }
    });
  },

  /**
   * Adiciona o atributo alt nas imagens de anuncio vindas do GTM
   */

  insertImgAlt: () => {
    document.addEventListener("DOMContentLoaded", function () {
      function adicionarAltNasImagens() {
        const imagens = document.querySelectorAll("img");
        imagens.forEach(function (img, index) {
          if (!img.hasAttribute('alt')) {
            img.setAttribute('alt', 'Imagem de anuncio: ' + (index + 1));
          }
        });
      }

      const observer = new MutationObserver(function (mutationsList) {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(function (node) {
              if (node.tagName === 'IMG' && !node.hasAttribute('alt')) {
                node.setAttribute('alt', 'Imagem de anuncio');
              } else if (node.querySelectorAll) {
                node.querySelectorAll('img').forEach(function (img) {
                  if (!img.hasAttribute('alt')) {
                    img.setAttribute('alt', 'Imagem de anuncio');
                  }
                });
              }
            });
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      adicionarAltNasImagens();
    }
    )
  },

  /**
   * Adiciona o atributo alt nas imagens de anuncio vindas do GTM
   */

  insertIframeTitle: () => {
    document.addEventListener("DOMContentLoaded", function () {

      function adicionarTitleNosIframes() {
        const iframes = document.querySelectorAll("iframe");
        iframes.forEach(function (iframe, index) {
          if (!iframe.hasAttribute('title')) {
            iframe.setAttribute('title', 'Iframe number: ' + (index + 1));
          }
        });
      }

      const observer = new MutationObserver(function (mutationsList) {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(function (node) {
              if (node.tagName === 'IFRAME' && !node.hasAttribute('title')) {
                node.setAttribute('title', 'Titulo do Iframe');
              } else if (node.querySelectorAll) {
                node.querySelectorAll('iframe').forEach(function (iframe,index) {
                  if (!iframe.hasAttribute('title')) {
                    iframe.setAttribute('title', 'Iframe number: ' + (index + 1));;
                  }
                });
              }
            });
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      adicionarTitleNosIframes();
    }
    )
  }

}

export default common

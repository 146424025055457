const modal = {

  controlModal: () => {
    $(document).on('click', '.actControlModal, .act-control-modal', function () {
      const activeModal = $(this).data('modal')

      const renderedSearch = $('[data-search-modal]').hasClass('-rendered')
      const renderedVideo = $('[data-video-modal]').hasClass('-rendered')

      // abre modal clicado e fundo escuro
      $('.wrapper-modal, #' + activeModal).toggleClass('-active')

      // desabilita scroll
      $('body').toggleClass('_overflow-hidden')

      if (activeModal === 'forecast-video' && !renderedVideo) {
        $('[data-video-modal]').addClass('-rendered')

        const videoId = $('#modal-video-player').data('video-id')

        // let truvidScript = document.createElement('script')
        // truvidScript.async = true
        // truvidScript.setAttribute('data-cfasync','false')
        // truvidScript.type = 'text/javascript' 
        // truvidScript.src = `https://stg.truvidplayer.com/index.php?sub_user_id=499&widget_id=3924&playlist_id=${videoId}&m=a&cb=` + (Math.random() * 10000000000000000)

        // let videoPlayer = document.getElementById('modal-video-player')
        // videoPlayer.insertBefore(truvidScript, videoPlayer.chilldNodes)

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'click_element',
          'id': 'modal-video'
        })
      }
    })

    $(document).on('click', '.act-close-modal', function () {
      // fecha modal e fundo escuro
      $('.wrapper-modal, .modal-content').removeClass('-active')

      // habilita scroll
      $('body').removeClass('_overflow-hidden')
    })

    $(document).on('click', '[data-open-article]', function () {
      const title = $(this).data('title')
      const image = $(this).data('image')
      const text = $(this).data('text')
      const user = $(this).data('user')
      const time = $(this).data('time')
      const modalTitle = $(this).data('modal-title')
      const modalLink = $(this).data('modal-link')

      document.getElementById('modal-article-title').innerHTML = modalTitle
      document.getElementById('modal-article-link').setAttribute('href', modalLink)

      // limpa a div com as informações sobre a notícia
      document.getElementById('modal-article').innerHTML = ''

      const titleElement = document.createElement('h3')
      titleElement.className = '-dark-blue -bold _margin-b-20'
      titleElement.innerText = title

      document.getElementById('modal-article').appendChild(titleElement)

      if (image) {
        const imgElement = document.createElement('img')
        imgElement.src = image
        imgElement.alt = title
        imgElement.style.maxWidth = '100%'

        document.getElementById('modal-article').appendChild(imgElement)
      }

      const textElement = document.createElement('p')
      textElement.className = '_margin-t-20 -gray -line-height-24'
      textElement.innerText = text

      document.getElementById('modal-article').appendChild(textElement)

      const userElement = document.createElement('p')
      userElement.className = '_margin-t-20 -gray'
      userElement.innerText = user

      document.getElementById('modal-article').appendChild(userElement)

      const timeElement = document.createElement('time')
      timeElement.className = '_margin-t-20 -gray'
      timeElement.innerText = time

      document.getElementById('modal-article').appendChild(timeElement)
    })
  }
}

export default modal